<template>
  <div class="page" id="reorganize">
    <el-form size="small" class="query-form" :inline="true" ref="inputForm" :model="inputForm"
             @keyup.enter.native="getDataList(1,1)"
             :rules="rules" label-width="100px">
      <el-form-item label="捐赠人：" prop="donors">
        <el-input v-model.trim="inputForm.donors" :maxlength="200" placeholder="请输入捐赠人名称"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="phone">
        <el-input v-model.trim="inputForm.phone" :maxlength="200" placeholder="请输入联系方式"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询</el-button>
        <el-button @click="resetting()" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <el-form-item class="f_r">
        <el-button type="primary" size="small"
                   icon="el-icon-circle-check"
                   v-show="hasPermissionButton(`collection:collect:${pageType}:allThrough`)"
                   @click="through(1,'',3)">批量通过
        </el-button>
        <el-button type="danger" size="small"
                   icon="el-icon-circle-close"
                   v-show="hasPermissionButton(`collection:collect:${pageType}:allReject`)"
                   @click="through(1,'',2)">批量驳回
        </el-button>
        <el-button type="primary" icon='el-icon-plus'
                   v-show="hasPermissionButton(`collection:collect:${pageType}:add`)"
                   @click="addData(0)">新增
        </el-button>
      </el-form-item>
    </el-form>

    <div class="bg-white">
      <div class="flex_b_c">
        <div v-if="pageType != 'toExamine'" class="tabsCls">
          <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                         :name="item.value"></el-tab-pane>
          </el-tabs>
        </div>
        <div></div>
        <div>
          <el-button size="small"
                     v-show="hasPermissionButton(`collection:collect:${pageType}:batchExpor`)"
                     @click="exportData()" v-noMoreClick>
            <i class="icon-piliangdaochu iconfont buIcon"/>
            批量导出
          </el-button>
          <el-button size="small"
                     icon="el-icon-position"
                     :disabled="dataListSelect.filter(item=>{return item.processState != 0}).length"
                     v-if="selectTab == 0 || selectTab == 3 "
                     v-show="hasPermissionButton(`collection:collect:${pageType}:submit`)"
                     @click="submit(1)">批量提交
          </el-button>
        </div>
      </div>
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          :height="pageType == 'registration' ? 'calc(100vh - 350px)' : 'calc(100vh - 330px)'"
          class="table"
          :row-key="'id'"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="图标" width="120px">
          <template slot-scope="scope">
            <div class="flex_a_c">
              <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
              <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.picMasterViewUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.picMasterViewUrl]"
              >
                <div slot="error" class="image-slot">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="require('@/assets/img/default.png')"
                      :fit="'cover'">
                  </el-image>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="donors" label="捐赠人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="联系方式" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.phone | specialCharacter }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="handoverMethod" label="藏品交接方式" min-width="120px"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" sortable label="创建时间" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="最后更新人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.updateDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(1, scope.row,scope.$index)"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:detail`)">详情
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="submit(2,scope.row)"
                       v-if="(selectTab === '0' && scope.row.processState == 0) || (selectTab === '3' && scope.row.processState == 0)"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:submit`)">提交
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="submit(2,scope.row,scope.$index)"
                       v-if="(selectTab === '0' && scope.row.processState == 2) || (selectTab === '2' && scope.row.processState == 2)"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:resubmit`)">
              重新提交
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(2, scope.row,scope.$index)"
                       v-if="scope.row.processState != 1 && scope.row.processState != 3"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:edit`)">修改
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="record(scope.row)"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:record`)">审核记录
            </el-button>
            <el-button style="margin-bottom: 10px" type="text" size="mini"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:through`)"
                       @click="through(2,scope.row,3)">通过
            </el-button>
            <el-button style="margin-bottom: 10px" type="text" size="mini"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:reject`)"
                       @click="through(2,scope.row,2)">驳回
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteDate(scope.row)"
                       v-if="scope.row.processState == 0 || scope.row.processState == 2"
                       v-show="hasPermissionButton(`collection:collect:${pageType}:delete`)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <record ref="record"></record>
    <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
  </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
  name: "registration",
  components: {reviewSuggestions, record},
  props: {
    pageType: {
      type: String,
      default: 'registration',
    }
  },
  data() {
    return {
      selectTab: 0,
      tabsList: [
        {
          name: '全部',
          value: '',
        },
        {
          name: '审核中',
          value: '1',
        },
        {
          name: '审核驳回',
          value: '2',
        },
        {
          name: '草稿',
          value: '3',
        },
      ],
      inputForm: {
        donors: '',
        phone: '',
        processState: '',
      },
      rules: {},
      dataList: [],
      loading: false,
      dataListSelect: [],
      pageNo: 1,
      pageNo2: 0,
      pageSize: 10,
      total: 0,
      searchRecord: {},
    }
  },

  mounted() {
    if (this.pageType == 'toExamine') {
      this.selectTab = 1
    }
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.inputForm = listSearch.inputForm
      this.pageNo2 = listSearch.pageNo
      this.pageSize = listSearch.pageSize
    }
    this.getDataList('', 1)
  },

  methods: {
    handleClick(tab, dividePage) {
      this.getDataList(1, 1)
    },

    getDataList(type, dividePage) {
      if (type == 1) {
        this.pageNo = 1
      }
      if (dividePage == 1) {
        this.$refs.multipleTable.clearSelection()
      }
      this.inputForm.processState = ''
      if (this.selectTab != 0) {
        if (this.selectTab == 1) {
          this.inputForm.processState = 1;
        } else if (this.selectTab == 2) {
          this.inputForm.processState = 2;
        } else if (this.selectTab == 3) {
          this.inputForm.processState = 0;
        }
      }
      this.loading = true
      let url = this.api.collection.collectiondonateList
      if (this.pageType == 'toExamine') {
        url = this.api.collection.listRev
      }
      this.searchRecord = {
        current: this.pageNo2 ? this.pageNo2 : this.pageNo,
        ...this.inputForm,
        size: this.pageSize,
      }
      this.$axios(url, this.searchRecord, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.pageNo > 1) {
            this.pageNo--
            this.getDataList()
          }
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.pageNo = listSearch.pageNo
            this.pageNo2 = 0
            sessionStorage.removeItem('listSearch')
          }
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //批量导出
    exportData() {
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelect.map(item => {
        return item.id
      })
      //导出
      let url = this.api.collection.batchExport
      let data = {
        ids: ids,
      }
      let fileName = '征集登记列表'
      this.exportExcel(url, data, fileName, 'get')
      this.$nextTick(() => {
        this.getDataList('', 1)
      })

    },

    //num 1多个 2单个  type 2驳回 3通过
    through(num, row, type) {
      let setData = []
      if (num == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        setData = this.dataListSelect
        this.$refs.suggestions.init(num, setData, type, '', this.api.collection.collectiondonateRevd, '', 1)
      } else {
        this.$axios(this.api.collection.listInfo, {ids: [row.id]}, 'get').then((res) => {
          if (res.status) {
            this.ruleInformation = res.data
            setData = [this.ruleInformation]
            this.$refs.suggestions.init(num, setData, type, '', this.api.collection.collectiondonateRevd, '', 1)
          }
        })
      }
    },

    //1批量提交 2提交
    submit(type, row) {
      let ids
      if (type == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`您是否确认提交数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.batchSubmit, ids, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('提交成功')
            this.getDataList('', 1)
          }
        })
      })
    },

    //0新增 1详情 2修改 2重新提交
    addData(num, row, index) {
      if (num != 0) {
        let listSearch = {
          inputForm: this.inputForm,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
        this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
        sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
      }
      if (this.pageType == 'toExamine') {
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: 5, pageSource: this.pageType, id: row.id}
        })
      } else if (num == 1 || num == 2) {
        //审核详情
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: num, pageSource: this.pageType, id: row.id}
        })
      } else {
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: num, pageSource: this.pageType}
        })
      }
    },

    //审核记录
    record(row) {
      this.$refs.record.init(row.id, '', 4)
    },

    //删除
    deleteDate(row) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.collectiondonateRemoveById + row.id, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },


    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style scoped>
/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}
</style>
